exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-code-of-conduct-tsx": () => import("./../../../src/pages/code-of-conduct.tsx" /* webpackChunkName: "component---src-pages-code-of-conduct-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-manifest-tsx": () => import("./../../../src/pages/manifest.tsx" /* webpackChunkName: "component---src-pages-manifest-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-remotes-tsx": () => import("./../../../src/pages/remotes.tsx" /* webpackChunkName: "component---src-pages-remotes-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-templates-docs-tsx": () => import("./../../../src/templates/docs.tsx" /* webpackChunkName: "component---src-templates-docs-tsx" */),
  "component---src-templates-printable-docs-tsx": () => import("./../../../src/templates/printable-docs.tsx" /* webpackChunkName: "component---src-templates-printable-docs-tsx" */),
  "component---src-templates-release-notes-tsx": () => import("./../../../src/templates/release-notes.tsx" /* webpackChunkName: "component---src-templates-release-notes-tsx" */)
}

